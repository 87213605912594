import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Table} from "react-bootstrap";

class Corona extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }


  componentDidMount() {
      function sortByKey(array, key, asc) {
          return array.sort(function(a, b) {
              var x = a[key]; var y = b[key];
              return ((x < y) ? -1 : ((x > y) ? 1 : 0)) * (asc?1:-1);
          });
      };
      let pager = {
          sort: { key : "cases"}
      };

    fetch("https://corona.lmao.ninja/countries")
        .then(res => res.json())
        .then(
            (result) => {
                let countries = result;
                // countries.forEach((country,index,countries)=>{
                //     let t_c = 0;
                //     let t_d = 0;
                //     let dates = Object.keys(country.timeline.cases);
                //     dates.forEach((_case,_index,_cases)=>{
                //         t_c += country.timeline.cases[_case];
                //     });
                //     let datesD = Object.keys(country.timeline.deaths);
                //     datesD.forEach((_death,_index,_deaths)=>{
                //
                //         country._key = index+"."+_index;
                //         t_d += country.timeline.cases[_death];
                //     });
                //     country._key = index;
                //     country._totalCases= t_c;
                //     country._totalDeaths= t_d;
                //
                // });
                // console.log(countries);
              this.setState({
                isLoaded: true,
                countries: sortByKey(countries, pager.sort.key, false)
              });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
        )
  }

  render() {
    const { error, isLoaded, countries } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (

          <Table striped bordered hover responsive size="sm">
                <thead>
                    <tr align={"center"}>
                        <th rowSpan={2}>البلد</th>
                        <th colSpan={6}>الحالات</th>
                        <th colSpan={3}>الوفيات</th>
                    </tr>
                    <tr align={"center"}>
                        <th>الجديدة</th>
                        <th>المجموع</th>
                        <th>النشطة</th>
                        <th>شفيت</th>
                        <th>الحرجة</th>
                        <th> لكل مليون</th>

                        <th>الجديدة</th>
                        <th>المجموع</th>
                        <th> لكل مليون</th>
                    </tr>
            </thead>
            <tbody>

            {countries.map(c => (
                <tr key={c.countryInfo._id || c.country} align={"center"} >
                    <td >
                        <img src={c.countryInfo.flag} alt={c.country} width={30}/>
                        <br/>
                        {c.country}
                    </td>

                    <td className={"bg-warning"}>{ "+" + c.todayCases }</td>
                    <td>{c.cases}</td>
                    <td>{ c.active }</td>
                    <td>{ c.recovered }</td>
                    <td>{ c.critical }</td>
                    <td>{ c.casesPerOneMillion }</td>


                    <td>{ c.todayDeaths }</td>
                    <td>{ c.deaths }</td>
                    <td>{ c.deathsPerOneMillion }</td>

                </tr>
            ))}

            </tbody>
          </Table>

      );
    }
  }
}

export default Corona;
